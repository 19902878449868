$small: 300px;
$medium: 1000px;

.whatsapp-link1 {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 180px;
  right: 14px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 300;

  @media screen and (min-width: $medium) {
    right: 15px;
    bottom: 95px;
  }
}
.fa-whatsapp {
  margin-top: 10px;
}
.fa-whatsapp .fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

#whatsapp-link-div a:hover,
whatsapp-link-div:visited {
  text-decoration: none;
  color: white;
}

.whatsappLoggedClass {
  bottom: 120px !important;

  @media screen and (min-width: $medium) {
    right: 15px;
    bottom: 55px !important;
  }
}
