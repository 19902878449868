#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th, #customers td {
  border: 1px solid #ddd;
  width: 800px;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

#customers {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  border-collapse: collapse;
}