#centre-path{fill:url(#centre-fill);}
#ring-1-path{fill:url(#ring-1-fill);}
#ring-2-path{fill:url(#ring-2-fill);}
#ring-3-path{fill:url(#ring-3-fill);}
$small: 300px;
$medium: 1000px;

#container {
  transform: scale(0.3);
  position: fixed;
  width: 300px;
  height: 300px;
  right: -80px;
  bottom: -90px;
  margin-left: -150px;
  margin-top: -150px;

  @media screen and (max-width: $medium) {
    transform: scale(0.2);
    right: -110px;
    bottom: -10px;
  }
}

.finocomp-power-button {
  width: 300px;
  cursor: pointer;
  animation: scale 2s infinite ease-in-out;
}

.finocomp-power-button.animated #centre {
  transform-origin: 50% 50%;
  animation: cw 0.8s;
  animation-delay: 0s;
}

.finocomp-power-button.animated #ring-1 {
  transform-origin: 50% 50%;
  animation: ccw 0.8s;
  animation-delay: 0.2s;
}

.finocomp-power-button.animated #ring-2 {
  transform-origin: 50% 50%;
  animation: cw 0.8s;
  animation-delay: 0.4s;
}

.finocomp-power-button.animated #ring-3 {
  transform-origin: 50% 50%;
  animation: ccw 0.8s;
  animation-delay: 0.5s;
}

@keyframes scale {
  0% {
    transform: scale(0.97);
    opacity: 0.6;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.97);
    opacity: 0.6;
  }
}
@keyframes cw {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes ccw {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}