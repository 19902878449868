$small: 300px;
$medium: 1000px;

.App {
  display: grid;
  grid-template-columns: 1fr auto;
}

$cards-amount: 3;

html {
  --card-width: 20vmin;
  --card-height: calc(var(--card-width) * 1.6);

  --color-table: #4d5265;
  --color-card-face: #ecebf3;
  --color-card-back: #920114;

  --color-suits-black: #0c120c;
  --color-suits-red: #c20114;

  --color-deck-shadow: #401111;

  font-family: Georgia, serif;
}

body,
html {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-image: url("../images/madeira.jpg");
  overflow: auto;
  // @media screen and (min-width: $medium) {
  //  overflow: hidden;
  // }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.container {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
  align-items: center;
}

.card {
  font-size: calc(var(--card-width) / 5);
  position: relative;
  cursor: pointer;
  display: block;
  width: var(--card-width);
  height: var(--card-height);
  transform-style: preserve-3d;
  backface-visibility: visible;
  perspective: 100px;

  $offset: 10px;

  &--♠,
  &--♣ {
    color: var(--color-suits-black);
  }

  &--♥,
  &--♦ {
    color: var(--color-suits-red);
  }

  &__face,
  &__back {
    position: absolute;
    top: $offset;
    left: $offset;
    right: $offset;
    bottom: $offset;
    border-radius: 0.4em;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.5);
    backface-visibility: hidden;
    transition: box-shadow 600ms ease-out;
  }

  &__back {
    transform: rotate3d(0, 1, 0, 0deg);
    background-color: #fff;
    background-image: url("../images/TrucoXP.jpg");
    background-position: center center;
    background-size: 95%;
    background-repeat: no-repeat;
  }

  &__face {
    padding: 0.5em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    transform: rotate3d(0, 1, 0, 180deg);
    background: var(--color-card-face);
  }

  &__suit {
    font-size: 2.5em;
  }

  &__value {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.2em;
    display: flex;
    flex-flow: column;
    align-items: center;
    transform: scale(-1);
    line-height: 1;

    &:first-of-type {
      bottom: auto;
      right: auto;
      top: 0;
      left: 0;
      transform: none;
    }

    &::before {
      content: attr(data-value);
    }
    &::after {
      font-size: 0.7em;
      content: attr(data-suit);
    }
  }
}

.hand {
  z-index: 200;
  position: relative;
  width: calc(var(--card-width) * #{$cards-amount + 1.5});
  height: var(--card-height);
  perspective: 1000px;
  perspective-origin: 50% 100%;

  &.rotate {
    z-index: 1;
    margin-top: 30px;
    transform: rotate(180deg);
  }

  &__card {
    --base-offset-y: 20%;
    --rotation-x-ratio: 0;
    --translate-z: 100px;
    --translate-y: 0%;
    --rotation-z: 0deg;

    position: absolute;
    top: 0;
    transform: translate3d(
        -50%,
        calc(var(--base-offset-y) + var(--translate-y)),
        var(--translate-z)
      )
      rotate3d(var(--rotation-x-ratio), 1, 0.1, var(--rotation-z));
    transition: all 620ms ease-out;
    will-change: left, top, transform;

    &:hover {
      --translate-z: 150px;
    }

    &--flipped {
      --rotation-z: 180deg;
      &.hidden {
        --rotation-z: 0deg;
      }
    }

    &--enter.hand__card:nth-child(n):nth-last-child(n),
    &--leave.hand__card:nth-child(n):nth-last-child(n) {
      --base-offset-y: 0%;
      --rotation-z: 0deg;
      --rotation-x-ratio: 0;
      --translate-y: 0%;
      --translate-z: 0px;
      --rotation-z: 180deg;

      &.hidden {
        --rotation-z: 0deg;
      }

      left: calc(50% + var(--card-width) / 2);
      top: -100%;
      pointer-events: none;

      .card__face,
      .card__back {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }
    &--enter {
      transition: none;
      z-index: 1;
    }
    &--leave {
      z-index: 1;
    }

    $offset-y: 10%;
    $base-x-ratio: 0.1;

    @for $i from 1 through ($cards-amount + 1) {
      $amount: if($i > $cards-amount, $cards-amount, $i);
      $shift: if($i > $cards-amount, 1, 0);
      $step: 100% / ($amount + 1);

      @for $j from 1 through $amount {
        &:nth-child(#{ $j + $shift }):nth-last-child(
            #{ $i - $j + 1 - $shift }
          ) {
          @if ($i > 1 and ($j == 1 or $j == $amount)) {
            --translate-y: #{$offset-y};
            --rotation-x-ratio: #{$base-x-ratio * if($j == 1, 1, -1)};
          }
          @if ($i > 3 and ($j == 2 or $j == 3)) {
            --rotation-x-ratio: #{$base-x-ratio * if($j == 2, 1, -1) / 2};
          }

          left: $step * $j;
        }
      }
    }
  }
}

.table {
  width: 100%;
  .deck {
    &.card {
      transform: rotate3d(0, 1, 0, 0deg);

      .card__back {
        box-shadow: 0 6px 0 0 var(--color-deck-shadow),
          2px 6px 16px 0 rgba(0, 0, 0, 0.6);
      }
    }
  }
  .manilha {
    left: calc(var(--card-width) / 2) !important;
    --rotation-x-ratio: 0 !important;
    top: calc(50vh - (var(--card-height)) - ((var(--card-height)) / 3));
  }
}

.yourHand .playerCards {
  border-radius: 10px;
  box-shadow: 0px 5px 20px green, 0px 5px 20px #fff;
  transition: all ease 0.5s;
}

.neon-card {
  background-color: #22232e;
  border-radius: 10px;
  box-shadow: 0px 5px 20px #00c2cb, 0px 5px 20px #fff;
  transition: all ease 0.5s;
}

.hand__card--leave {
  box-shadow: none !important;
}

.link-no-decorator {
  color: #f0a500;
  text-decoration: underline;
  cursor: pointer;
}
