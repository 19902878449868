$small: 300px;
$medium: 1000px;

header {
  display: flex;
}

.text {
  width: 70%;
}

.wanted {
  display: inline-block;
  background-image: url("../../assets/images/rank-background.gif");
  background-size: 100%;
  background-position: center top;
  border-radius: 5px;

  width: 100%;
  margin-top: 0px;
  padding-bottom: 90px;

  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 200px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  @media screen and (min-width: $medium) {
    max-width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 60px;

    img {
      margin: 0;
    }
  }
}

.ranking-headline {
  display: inline-block;
  width: 100%;
  background-color: brown;
  color: white;

  p {
    text-align: center;
    margin: 5px;
  }
}

.content-table {
  border-collapse: collapse;
  font-size: 0.9em;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  @media screen and (max-width: $medium) {
    th {
      display: none;
    }
    td {
      display: none;
      &.mobile {
        display: block;
      }
    }
  }

  .mobile {
    display: none;
    @media screen and (max-width: $medium) {
      display: block;
    }
  }

  .avatar {
    text-align: center;
  }

  b {
    font-weight: bold;
  }

  thead tr {
    background-color: #000;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }

  th,
  td {
    padding: 12px 15px;
  }

  img {
    width: 90px;
  }

  tbody tr {
    border-bottom: 1px solid #dddddd;

    &:nth-of-type(even) {
      background-color: rgba(255, 255, 255, 0.5);
    }

    &:last-of-type {
      border-bottom: 2px solid #000;
    }

    font-weight: bold;
    color: #000;
    text-shadow: #ccc 1px 0 1px;
  }
}
