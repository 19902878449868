$small: 300px;
$medium: 1000px;

.nav-footer-mobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 95px;
  background-color: black;
  display: none;
  @media screen and (max-width: $medium) {
    display: block;
    padding-bottom: 7px;
    z-index: 200;
  }
}

.nav-footer-mobile li {
  color: white;
  margin-top: 10px;
  flex: 1;
}

.nav-footer-mobile ul, .nav-footer-mobile li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-footer-mobile a {
  text-decoration: none;
  color: white;
}

.nav-footer-mobile span {
  text-decoration: none;
  color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 26px;
  margin-top: 0px;
}

.nav-footer-mobile div {
  text-align: center;
}

.nav-footer-mobile ul {
  align-items: center;
  display: flex;
  justify-content: center;
}

.nav-footer-mobile .downloadMenuBar {
  height: 30px;
  width: 100%;
  background-color: brown;
  text-align: center;
}

.nav-footer-mobile .upperMenuBar {
  height: 30px;
  width: 100%;
  background-color: brown;
  text-align: center;
}

.labelIcon {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 9px;
  font-size: 15px;
}

.labelMenu {
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: -4px;
  font-size: 12px;
}