.footer-privacidade {
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 40px;
  font-size: 14px;
}

.footer-privacidade a {
  color: #fff;
  text-decoration: none;
}

.footer-privacidade a:hover {
  text-decoration: underline;
}

.item-footer {
  padding: 0px 12px;
}
