.fixed-button-container {
  display: none;
}
.chat-app-container {
  display: none;
}
@media screen and (min-width: 1000px) {
  .fixed-button-container {
    position: fixed;
    right: 0px;
    bottom: 116px;
    z-index: 999;
    justify-content: center !important;
    display: none;
  }

  .fixed-button-container button {
    position: fixed;
    z-index: 10;
    bottom: 158px;
    right: 15px;
    border-radius: 184px;
  }

  .chat-app-container {
    display: block;
    position: fixed;
    right: 20px;
    bottom: 186px;
    z-index: 5;
    max-width: 500px;
    min-width: 320px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  /* Media Query */

  .fixed-button-container {
    right: 0px;
    bottom: 47px;
    display: block;
  }

  .chat-app-container {
    right: 0px;
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }
}
