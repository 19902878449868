$black: #071011;
$white: #fff;
$red: tomato;
$standard: 10px;
$card-height: 450px;
$card-width: 300px;

.profile-page {
  .background-inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    background: url("https://images.unsplash.com/photo-1447433589675-4aaa569f3e05?ixlib=rb-0.3.5&s=4222852e25e0f57d9485f7889957e99a&auto=format&fit=crop&w=2000&q=80");
    background-size: cover;
    background: #ccc;
    background-position: 0 50%;
    background: #deb493;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0);
    }
  }

  .outer-div,
  .inner-div {
    // max-width: $card-width;
    margin: 0 auto;
    position: relative;
  }

  .outer-div {
    perspective: 900px;
    perspective-origin: 50% calc(50% - 18em);
  }

  .inner-div {
    margin: 0 auto;
    border-radius: 5px;
    font-weight: 400;
    color: $white;
    font-size: 1rem;
    text-align: center;
    // transition: all 0.6s cubic-bezier(0.8, -0.4, 0.2, 1.7);
    // transform-style: preserve-3d;

    &:hover .social-icon {
      opacity: 1;
      top: 0;
    }

    /*&:hover .front__face-photo,
    &:hover .front__footer {
      opacity: 1;
    }*/
  }

  .outer-div:hover .inner-div {
    // transform: rotateY(180deg);
  }

  .front,
  .back {
    position: relative;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    background-image: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      );
  }

  .front {
    cursor: pointer;
    backface-visibility: hidden;
    border-radius: 5px;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5),
      0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }

  .front__bkg-photo {
    position: relative;
    height: 150px;
    width: 100%;
    background: url("../../assets/images/oldWestCity.jpg") no-repeat;
    background-size: cover;
    backface-visibility: hidden;
    overflow: hidden;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .front__face-photo {
    position: absolute;
    top: 82px;
    left: 95px;
    height: 120px;
    width: 120px;
    margin: 0 auto;
    border-radius: 50%;
    border: 5px solid $white;
    background: url("../../assets/images/avatar/avatar1.jpg") no-repeat;
    background-size: contain;
    overflow: hidden;
    /* backface-visibility: hidden;
    transition: all 0.6s cubic-bezier(0.8, -0.4, 0.2, 1.7);
    z-index: 3;*/
  }

  .front__text {
    position: relative;
    top: -55px;
    margin: 0 auto;
    font-family: "Montserrat";
    font-size: 18px;
    backface-visibility: hidden;

    .front__text-header {
      font-weight: 700;
      font-family: "Oswald";
      text-transform: uppercase;
      font-size: 20px;
    }

    .front__text-para {
      position: relative;
      top: -5px;

      color: #000;
      font-size: 14px;
      letter-spacing: 0.4px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
    }

    .front-icons {
      position: relative;
      top: 0;
      font-size: 14px;
      margin-right: 6px;
      color: gray;
    }

    .front__text-hover {
      position: relative;
      top: 10px;
      font-size: 10px;
      color: $red;
      backface-visibility: hidden;

      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.4px;

      border: 2px solid $red;
      padding: 8px 15px;
      border-radius: 30px;

      background: $red;
      color: $white;
    }
  }

  .back {
    transform: rotateY(180deg);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .social-media-wrapper {
    font-size: 36px;

    .social-icon {
      position: relative;
      top: 20px;
      margin-left: 5px;
      margin-right: 5px;
      opacity: 0;
      color: #fff;
      transition: all 0.4s cubic-bezier(0.3, 0.7, 0.1, 1.9);
    }

    .social-icon:nth-child(1) {
      transition-delay: 0.6s;
    }

    .social-icon:nth-child(2) {
      transition-delay: 0.7s;
    }

    .social-icon:nth-child(3) {
      transition-delay: 0.8s;
    }

    .social-icon:nth-child(4) {
      transition-delay: 0.9s;
    }
  }

  .fab {
    position: relative;
    top: 0;
    left: 0;
    transition: all 200ms ease-in-out;
  }

  .fab:hover {
    top: -5px;
  }

  h2 {
    color: #f0a500;
  }
}

.jogador-do-agente {
  color: white;
  width: 100%;
  padding: 5px;
  cursor: auto;
}
