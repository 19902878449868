$small: 300px;
$medium: 1000px;
$large: 1200px;

// Variáveis
$switch-width: 3em;
$switch-height: 1.5em;
$slider-width: 1.5em;
$slider-height: 1.5em;
$slider-thumb-width: 1em;
$slider-thumb-height: 1em;
$slider-thumb-position: 0.25em;
$font-size: 1em;

.form-panel {
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: #b9b9b9;

  h2 {
    color: #f0a500;
  }
  input,
  input[type="radio"] + label,
  input[type="checkbox"] + label:before,
  select option,
  select {
    width: 100%;
    padding: 1em;
    line-height: 1.4;
    background-color: #f9f9f9;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  input:focus {
    outline: 0;
    border-color: #bd8200;
  }
  input:focus + .input-icon i {
    color: #f0a500;
  }
  input:focus + .input-icon:after {
    border-right-color: #f0a500;
  }
  input[type="radio"] {
    display: none;
  }
  input[type="radio"] + label,
  select {
    display: inline-block;
    width: 50%;
    text-align: center;
    float: left;
    border-radius: 0;
  }
  input[type="radio"] + label:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  input[type="radio"] + label:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  input[type="radio"] + label i {
    padding-right: 0.4em;
  }
  input[type="radio"]:checked + label,
  input:checked + label:before,
  select:focus,
  select:active {
    background-color: #f0a500;
    color: #fff;
    border-color: #bd8200;
  }
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label {
    position: relative;
    display: block;
    padding-left: 1.6em;
  }
  input[type="checkbox"] + label:before {
    position: absolute;
    top: 0.2em;
    left: 0;
    display: block;
    width: 1em;
    height: 1em;
    padding: 0;
    content: "";
  }
  input[type="checkbox"] + label:after {
    position: absolute;
    top: 0.45em;
    left: 0.2em;
    font-size: 0.8em;
    color: #fff;
    opacity: 0;
    font-family: FontAwesome;
    content: "\f00c";
  }
  input:checked + label:after {
    opacity: 1;
  }
  select {
    height: 3.4em;
    line-height: 2;
  }
  select:first-of-type {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  select:last-of-type {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  select:focus,
  select:active {
    outline: 0;
  }
  select option {
    background-color: #f0a500;
    color: #fff;
  }
  .input-group {
    margin-bottom: 1em;
    zoom: 1;
  }
  .input-group:before,
  .input-group:after {
    content: "";
    display: table;
  }
  .input-group:after {
    clear: both;
  }
  .input-group-icon {
    position: relative;
    font-size: 15px;
  }
  .input-group-icon input {
    padding-left: 4.4em;
    font-size: 15px;
  }
  .input-group-icon .input-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 3.4em;
    height: 3.4em;
    line-height: 3.4em;
    text-align: center;
    pointer-events: none;
    font-size: 15px;
  }
  .input-group-icon .input-icon:after {
    position: absolute;
    top: 0.6em;
    bottom: 0.6em;
    left: 3.4em;
    display: block;
    border-right: 1px solid #e5e5e5;
    content: "";
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .input-group-icon .input-icon i {
    -webkit-transition: 0.35s ease-in-out;
    -moz-transition: 0.35s ease-in-out;
    -o-transition: 0.35s ease-in-out;
    transition: 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
  }
  .container-form {
    max-width: 38em;
    padding: 1em 2em 2em 2em;
    margin: 0em auto;
    background-color: #fff;
    border-radius: 4.2px;
    box-shadow: 0px 3px 10px -2px rgba(0, 0, 0, 0.2);
    background-color: rgba(0, 0, 0, 0.4);
    background-image: repeating-linear-gradient(
        45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      ),
      repeating-linear-gradient(
        -45deg,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3) 10%,
        transparent 0%,
        transparent 20%
      );
  }
  .row {
    zoom: 1;
  }
  .row:before,
  .row:after {
    content: "";
    display: table;
  }
  .row:after {
    clear: both;
  }
  .col-half {
    padding-right: 10px;
    float: left;
    width: 50%;
  }
  .col-half:last-of-type {
    padding-right: 0;
  }
  .col-third {
    padding-right: 10px;
    float: left;
    width: 33.33333333%;
  }
  .col-third:last-of-type {
    padding-right: 0;
  }
  @media only screen and (max-width: 540px) {
    .col-half {
      width: 100%;
      padding-right: 0;
    }
  }

  @media screen and (max-width: $medium) {
    .container-form {
      width: 90%;
    }
  }
}

.signUp {
  // .switch {
  //   position: relative;
  //   display: inline-block;
  //   width: 3.75em; /* 60px em unidades 'em' */
  //   height: 2.125em; /* 34px em unidades 'em' */
  // }

  // .switch input {
  //   opacity: 0;
  //   width: 0;
  //   height: 0;
  // }

  // .slider {
  //   position: absolute;
  //   cursor: pointer;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background-color: #ccc;
  //   -webkit-transition: 0.4s;
  //   transition: 0.4s;
  // }

  // .slider:before {
  //   position: absolute;
  //   content: "";
  //   height: 1.625em; /* 26px em unidades 'em' */
  //   width: 1.625em; /* 26px em unidades 'em' */
  //   left: 0.25em; /* 4px em unidades 'em' */
  //   bottom: 0.25em; /* 4px em unidades 'em' */
  //   background-color: white;
  //   -webkit-transition: 0.4s;
  //   transition: 0.4s;
  // }

  // input:checked + .slider {
  //   background-color: #f0a500;
  // }

  // input:focus + .slider {
  //   box-shadow: 0 0 0.0625em #f0a500; /* 1px em unidades 'em' */
  // }

  // input:checked + .slider:before {
  //   -webkit-transform: translateX(1.625em); /* 26px em unidades 'em' */
  //   -ms-transform: translateX(1.625em); /* 26px em unidades 'em' */
  //   transform: translateX(1.625em); /* 26px em unidades 'em' */
  // }

  // /* Rounded sliders */
  // .slider.round {
  //   border-radius: 2.125em; /* 34px em unidades 'em' */
  // }

  // .slider.round:before {
  //   border-radius: 50%;
  // }

  // .switch-container {
  //   display: flex;
  //   align-items: center;
  //   margin-bottom: 10px;
  // }

  // .switch {
  //   position: relative;
  //   display: inline-block;
  //   width: $switch-width;
  //   height: $switch-height;
  //   margin-right: 10px;
  // }

  // .slider {
  //   width: $slider-width;
  //   height: $slider-height;
  //   border-radius: $slider-height;
  // }

  // .slider:before {
  //   width: $slider-thumb-width;
  //   height: $slider-thumb-height;
  //   border-radius: $slider-thumb-height;
  //   left: $slider-thumb-position;
  // }

  // .switch-text {
  //   font-size: $font-size;
  //   margin-left: 10px;
  // }

  .slider-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 2.5em;
    height: 1.5em;
    margin-right: 1em;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 1.5em;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 1em;
    width: 1em;
    left: 0.25em;
    bottom: 0.25em;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #f0a500;
  }

  input:checked + .slider:before {
    transform: translateX(1em);
  }

  .slider-label {
    font-size: 1.2em;
    font-weight: bold;
  }
}
