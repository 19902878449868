$small: 300px;
$medium: 1000px;

.discord-button {
  position: fixed;
  z-index: 10;
  bottom: 116px;
  right: 14px;
  border-radius: 184px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  @media screen and (min-width: $medium) {
    position: fixed;
    z-index: 10;
    border-radius: 184px;
    right: 45px;
    bottom: 47px;
  }
}
