.backButton,.backButton:link,.backButton:visited,.backButton:hover,.backButton:focus,.backButton:active{
  color: white;
  text-decoration: none;
}

.pix-copia-cola{
  color: #fff;
  background-image: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 10%, transparent 0%, transparent 20%), repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 10%, transparent 0%, transparent 20%);
  padding: 20px 30px;
  border-radius: 3px;
  font-size: 22px;
}