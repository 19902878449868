.chat-app {
  max-width: 728px;
  margin: 0 auto;
  background-color: #282c34;

  header {
    background-color: #181717;
    height: 10vh;
    min-height: 50px;
    color: white;
    position: fixed;
    width: 100%;
    max-width: 728px;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 99;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  section {
    min-height: 100vh;
    background-color: #313338;
  }

  main {
    overflow-x: hidden;
    padding: 10px;
    height: 93vh;
    overflow-y: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  main::-webkit-scrollbar {
    width: 0.25rem;
  }

  main::-webkit-scrollbar-track {
    background: #1e1e24;
  }

  main::-webkit-scrollbar-thumb {
    background: #6649b8;
  }

  form {
    height: 10vh;
    position: absolute;
    bottom: 0;
    background-color: rgb(24, 23, 23);
    width: 100%;
    max-width: 728px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.5rem;
  }

  form button {
    width: 20%;
    background-color: #5865f2;
  }

  input {
    line-height: 1.5;
    width: 100%;
    font-size: 1.5rem;
    background: rgb(58, 58, 58);
    color: white;
    outline: none;
    border: none;
    padding: 0 30px;
  }

  button {
    background-color: #4752ca; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    font-size: 1.25rem;
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #4752ca !important;
  }

  .sign-in {
    color: #282c34;
    background: white;
    max-width: 400px;
    margin: 0 auto;
  }

  ul,
  li {
    text-align: left;
    list-style: none;
  }

  p {
    max-width: 500px;
    border-radius: 25px;
    position: relative;
    color: white;
    margin: 0;
  }

  .message {
    padding: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .sent p {
    color: white;
    padding: 5px;
    border-radius: 5px;
  }

  .received p {
    padding: 5px;
    border-radius: 5px;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 2px 5px;
  }

  form {
    position: fixed;
    bottom: 0px;
    flex-direction: column;
    height: auto;
    width: 500px;
  }

  input {
    width: calc(100% - 60px);
  }

  form button {
    width: 100%;
  }

  input {
    font-size: 1.2rem;
    padding: 10px 30px;
  }

  section {
    padding-bottom: 0px;
  }

  // Adicione padding-bottom para evitar sobreposição
  main {
    padding-bottom: 0px;
  }

  form {
    position: fixed;
    bottom: 100px;
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  input {
    width: calc(100% - 60px);
  }

  form button {
    width: 100%;
  }

  input {
    font-size: 1.2rem;
    padding: 10px 30px;
  }

  section {
    padding-bottom: 100px;
  }

  // Adicione padding-bottom para evitar sobreposição
  main {
    padding-bottom: 150px;
  }
}
